<template>
    <b-table ref="locationTable" hover :items="locations"
             :fields="locationFields">
        <template v-slot:cell(name)="data">
            <b-form-input
                    v-if="data.item.isEditable"
                    v-model="selectedLocation.name"
                    type="text"/>
            <span v-else>{{ data.item.name }}</span>
        </template>
        <template v-slot:cell(address)="data">
            <div v-if="data.item.isEditable">
                <input type="text" v-model="selectedLocation.address" class="form-control"/>
            </div>
            <div v-else>{{ data.item.address }}</div>
        </template>
        <template v-slot:cell(suburb)="data">
            <div v-if="data.item.isEditable">
                <input type="text" v-model="selectedLocation.suburb" class="form-control"/>
            </div>
            <div v-else>{{ data.item.suburb }}</div>
        </template>
        <template v-slot:cell(state)="data">
            <div v-if="data.item.isEditable">
              <b-form-select
                  :options="addressStates"
                  v-model="selectedLocation.state"
                  text-field="value"
                  value-field="key"
                  placeholder="Select state"
              >
                <template #first>
                  <b-form-select-option value="null" disabled hidden>Select state</b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <div v-else-if="data.item.state">{{ getState(data.item.state) }}</div>
        </template>
        <template v-slot:cell(postcode)="data">
            <div v-if="data.item.isEditable">
                <input type="text" v-model="selectedLocation.postcode"
                       class="form-control"/>
            </div>
            <div v-else>{{ data.item.postcode }}</div>
        </template>
        <template v-slot:cell(phone)="data">
            <div v-if="data.item.isEditable">
                <input type="text" v-model="selectedLocation.phone" class="form-control"/>
            </div>
            <div v-else>{{ data.item.phone }}</div>
        </template>
        <template v-slot:cell(openHours)="data">
            <div v-if="data.item.isEditable">
                <input type="text" v-model="selectedLocation.openHours"
                       class="form-control"/>
            </div>
            <div v-else>{{ data.item.openHours }}</div>
        </template>
        <template v-slot:cell(closingHours)="data">
            <div v-if="data.item.isEditable">
                <input type="text" v-model="selectedLocation.closingHours"
                       class="form-control"/>
            </div>
            <div v-else>{{ data.item.closingHours }}</div>
        </template>
        <template v-slot:cell(delivery)="data">
            <div v-if="data.item.isEditable">
              <multiselect
                  v-model="selectedLocation.delivery"
                  :options="deliveries"
                  :showLabels="false"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Select delivery"
              />
            </div>
            <div v-else>{{ data.item.delivery }}</div>
        </template>
        <template v-slot:cell(action)="data">
            <div class="action-btn">
                <button
                        v-if="!data.item.isEditable"
                        class="clickable btn_edit"
                        v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Edit Location', placement:'leftbottom'}"
                        @click="onClickEditRow(data.index)"
                ></button>
                <button
                        v-else
                        class="clickable btn_save"
                        v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Save Location', placement:'leftbottom'}"
                        @click="onClickSaveRow(data.index)"
                ></button>
                <button
                        v-if="!data.item.isEditable"
                        class="clickable btn_trush"
                        v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Delete Location', placement:'leftbottom'}"
                        @click="onClickRemoveRow(data.index)"></button>
            </div>
        </template>
    </b-table>

</template>


<script>
    /*eslint-disable */
    import {mapGetters} from 'vuex';
    import Axios from "axios";
    import Multiselect from "vue-multiselect";

    export default {
        name: "settings-business-profile-locations",
        props: {
          addLocationButtonPressed: Number,
          saveLocationButtonPressed: Number,
        },
        components: {
          Multiselect,
        },
        mounted(){
          this.loadLocations();

        },
        computed: {
          ...mapGetters({
            // currentUser: "currentUser/getCurrentUserInfo",
            addressStates: 'addressStates',
            // addressCountries: 'addressCountries',
          }),
        },
        watch: {
          addLocationButtonPressed(){
            this.addLocationField();
          },
          saveLocationButtonPressed(){

          },
        },
        data() {
            return {
                deliveries: [
                    'Road'
                ],
                locations: [],
                deleteLocations: [],
                locationFields: [
                    {
                        label: "Name",
                        key: "name",
                        sortable: true,
                        tdClass: "table-body font-weight-bold",
                        thClass: "table-header"
                    },
                    {
                        label: "Address",
                        key: "address",
                        sortable: true,
                        tdClass: "table-body",
                        thClass: "table-header"
                    },
                    {
                        label: "Suburb",
                        key: "suburb",
                        sortable: true,
                        tdClass: "table-body",
                        thClass: "table-header"
                    },
                    {
                        label: "State",
                        key: "state",
                        sortable: true,
                        tdClass: "table-body",
                        thClass: "table-header"
                    },
                    {
                        label: "Postcode",
                        key: "postcode",
                        sortable: true,
                        tdClass: "table-body",
                        thClass: "table-header"
                    },
                    {
                        label: "Phone",
                        key: "phone",
                        sortable: true,
                        tdClass: "table-body",
                        thClass: "table-header"
                    },
                    {
                        label: "Open Hours",
                        key: "openHours",
                        sortable: true,
                        tdClass: "table-body",
                        thClass: "table-header"
                    },
                    {
                        label: "Closing Hours",
                        key: "closingHours",
                        sortable: true,
                        tdClass: "table-body",
                        thClass: "table-header"
                    },
                    {
                        label: "Delivery",
                        key: "delivery",
                        sortable: true,
                        tdClass: "table-body",
                        thClass: "table-header"
                    },
                    {
                        label: "Action",
                        key: "action",
                        sortable: true,
                        tdClass: "table-body",
                        thClass: "table-header action-btns"
                    }
                ],
                emptyLocation: {
                  id: false,
                  name: '',
                  address: '',
                  suburb: '',
                  state: '',
                  postcode: '',
                  phone: '',
                  openHours: '',
                  closingHours: '',
                  delivery: '',
                  isEditable: false,
                },
              selectedLocation: null,
            }
        },
        methods:{
            getState(k){
              let stateValue = _.find(this.addressStates, (item) => {
                return k === item.key;
              });
              return stateValue.value;
            },
            onClickRemoveRow(index){
              this.deleteLocation(this.locations[index]);
            },
            onClickSaveRow(index){
              let loc = this.selectedLocation;
              if(loc.id){
                this.updateLocation(loc);
              } else {
                this.addLocation(loc);
              }
            },
            onClickEditRow(index){
              this.selectedLocation = this.locations[index];
              this.locations[index].isEditable = true;
            },
            loadLocations(){
                Axios.get('/ir/company/locations')
                  .then(res => {
                    if(res && res.data && res.data._status){
                      this.locations = res.data.locations;
                    }
                  })
                  .catch(e => this.$toast.error(e))
            },
            addLocationField(){
              let n = Object.assign({}, this.emptyLocation);
              n.isEditable = true;
              this.selectedLocation = n;
              this.locations.push(n);
            },
            addLocation(data){
              Axios.post('/ir/company/location/add', data)
                  .then(res => {
                    if(res && res.data && res.data._status){
                      this.$toast.success('Success!');
                      this.loadLocations();
                    }
                  })
                  .catch(e => this.$toast.error(e))
            },
            updateLocation(data){
              Axios.post(`/ir/company/location/${data.id}/update`, data)
                  .then(res => {
                    if(res && res.data && res.data._status){
                      this.$toast.success('Success!');
                      this.loadLocations();
                    }
                  })
                  .catch(e => this.$toast.error(e))
            },
            deleteLocation(data){
              Axios.post(`/ir/company/location/${data.id}/delete`, data)
                  .then(res => {
                    if(res && res.data && res.data._status){
                      this.$toast.success('Success!');
                      this.loadLocations();
                    }
                  })
                  .catch(e => this.$toast.error(e))
            }
        }
    }

</script>
