<template>
    <div class="row business-details">
        <div class="col-md-6">
            <div class="card mt-3">
                <div class="card-header">Details</div>
                <div class="card-block mt-3">
                    <div class="form-group row">
                        <label class="col col-form-label col-lg-4">Business Name</label>
                        <div class="col-lg-8">
                            <input v-model="companyInfo.businessName" type="text"
                                   class="form-control" placeholder/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col col-form-label col-lg-4">ABN</label>
                        <div class="col-lg-8">
                            <input v-model="companyInfo.abn" type="text"
                                   class="form-control" placeholder/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col col-form-label col-lg-4">Business Type</label>
                        <div class="col-lg-8">
                            {{companyInfo.type}}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col col-form-label col-lg-4">Phone</label>
                        <div class="col-lg-8">
                            <input v-model="companyInfo.phone" type="text"
                                   class="form-control" placeholder/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col col-form-label col-lg-4">Fax</label>
                        <div class="col-lg-8">
                            <input v-model="companyInfo.fax" type="text"
                                   class="form-control" placeholder/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col col-form-label col-lg-4">Email</label>
                        <div class="col-lg-8">
                            <input v-model="companyInfo.email" type="text"
                                   class="form-control" placeholder/>
                            <small
                                    class="col-form-label form-text show-help-for-body"
                                    v-if="!$v.companyInfo.email.email"
                            >Please enter a valid email address
                            </small>
                        </div>
                    </div>

                </div>
            </div>
          <div class="card mt-3">
            <div class="card-header">Primary Business Address</div>
            <div class="card-block mt-3">
              <div class="form-group row">
                <label class="col col-form-label col-lg-4">Search Address</label>
                <div class="col-lg-8">
                  <google-location
                      type="text"
                      v-model="companyInfo.address.address"
                      id="location_my_account"
                      placeholder="Search for an Address"
                  ></google-location>
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label col-lg-4">Unit/Level</label>
                <div class="col-lg-8">
                  <input v-model="companyInfo.address.unitLevelLot" type="text"
                         class="form-control" placeholder/>
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label col-lg-4">Address Line</label>
                <div class="col-lg-8">
                  <input v-model="companyInfo.address.street" type="text"
                         class="form-control" placeholder/>
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label col-lg-4">Suburb</label>
                <div class="col-lg-8">
                  <input v-model="companyInfo.address.suburb" type="text"
                         class="form-control" placeholder/>
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label col-lg-4">State</label>
                <div class="col-lg-8">
                  <multiselect
                      v-model="companyInfo.address.state"
                      :options="addressStates"
                      track-by="value"
                      label="value"
                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                      placeholder="Select state"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label col-lg-4">Postcode</label>
                <div class="col-lg-8">
                  <input v-model="companyInfo.address.postcode" type="text"
                         class="form-control" placeholder/>
                </div>
              </div>
              <div class="form-group row">
                <label class="col col-form-label col-lg-4">Country</label>
                <div class="col-lg-8">
                  <multiselect
                      v-model="companyInfo.address.country"
                      :options="addressCountries"
                      track-by="value"
                      label="value"
                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                      placeholder="Select country"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
            <div class="w-75 mt-3">
                <div v-if="companyInfo.logoImgPath" class="mt-3">
                    <div class="company-logo w-100">
                        <img :src="companyInfo.logoImgPath" class="company-logo w-100"
                             alt="Company Logo"/>
                    </div>
                    <button
                            @click="clickDeleteCompanyLogo"
                            type="button"
                            class="btn btn-danger w-100 mt-2"
                    >Delete Logo
                    </button>
                </div>
                <vue-dropzone
                        v-else
                        v-on:vdropzone-file-added="addImgUpload"
                        v-on:vdropzone-success="successImgUploadBusinessProfile"
                        v-on:vdropzone-complete="completeImgUpload"
                        v-on:vdropzone-sending="sendImgUpload"
                        ref="imgUploadDropBusiness"
                        id="imgUploadDropBusiness"
                        class="dropify-wrapper dropzoneContainer mt-3"
                        style="height: auto;"
                        :options="companyLogoDropzoneOptions"
                        :useCustomSlot="true"
                >
                    <div class="dropify-message" style="transform: translateY(0%);">
                        <i class="bx bx-cloud-upload"></i>
                        <p class="drop-down-text">Drag and Drop Logo Here</p>
                        <p class="text-or mb-0-5">Or</p>
                        <div class="text-sm-center mb-1 ex-image-upload-buttons">
                            <button
                                    type="button"
                                    class="btn btn-primary waves-effect waves-light browse-btn"
                            >Browse Logo
                            </button>
                        </div>
                    </div>
                </vue-dropzone>
            </div>
        </div>
    </div>

</template>

<script>
    /*eslint-disable */
    import {mapGetters} from 'vuex';
    import multiselect from 'vue-multiselect';
    import {email} from "vuelidate/lib/validators";
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";
    import GoogleLocation from "@/components/utility/google-location";
    import Axios from "axios";
    import {appConfig} from "@/config";

    export default {
        name: "settings-business-profile-details",
        props:{
        },
        components: {
            multiselect,
            vueDropzone: vue2Dropzone,
            GoogleLocation,
        },

        validations: {
            companyInfo: {
                email: {
                    email
                }
            }
        },
        data() {
            return {
                companyLogoDropzoneOptions: {
                    headers: {Authorization: localStorage.getItem("token")},
                    resizeWidth: 900,
                    resizeQuality: 0.7,
                    url: appConfig.baseAPIURL + "/ir/company/logo/upload",
                    method: "post",
                    maxFilesize: 50,
                    // maxFiles: 2,     //Can be used to limit the maximum number of files that will be handled by this Dropzone
                    thumbnailWidth: 200,
                    timeout: 300000,
                    addRemoveLinks: false,
                    acceptedFiles: "image/jpeg, image/jpg, image/png",
                    clickable: ['.browse-btn'],
                    dictFileTooBig:
                        "Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again"
                },
                companyInfo: {
                    address: {}
                },
            };
        },
        methods:{
            clickDeleteCompanyLogo: function () {
                NProgress.start();
                this.companyInfo.logoImgPath = '';
                this.$store.dispatch('currentCompany/removeCompanyLogo')
                    .then(() => {
                        this.$toast.success('Company logo has been removed');
                        this.$store.dispatch('currentCompany/loadCompanyInfo', {}, {root: true});
                    })
                    .catch(error => {
                        this.$toastr.w(error);

                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            clickSave() {
                NProgress.start();
                Axios.post('/ir/company/update', {details:this.companyInfo})
                    .then(response => {
                        this.$store.dispatch('currentCompany/loadCompanyInfo', {}, {root: true});
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(()=>{
                        NProgress.done();
                    });
            },
            setCompanyInfo: function (company) {
                if (!_.isEmpty(company)) {
                    let val = JSON.parse(JSON.stringify(company)),
                        country = _.filter(this.addressCountries, item => {
                            return item.key === val.address.country;
                        }),
                        state = _.filter(this.addressStates, item => {
                            return item.key === val.address.state;
                        });

                    this.companyInfo = {
                        about: val.about,
                        businessName: val.businessName,
                        abn: val.abn,
                        type: val.type,
                        phone: val.phone,
                        fax: val.fax,
                        email: val.email,
                        logoImgPath: val.logoImgPath,
                        activeXeroAuth: val.activeXeroAuth,
                        address: {
                            address: val.address.address,
                            unitLevelLot: val.address.unitLevelLot,
                            street: val.address.street,
                            suburb: val.address.suburb,
                            postcode: val.address.postcode,
                            state: (!_.isEmpty(state)) ? state[0] : this.addressStates[0],
                            country: (!_.isEmpty(country)) ? country[0] : this.addressCountries[0]
                        },
                    };
                    if (this.companyInfo && this.companyInfo.type === 'Insurance') {
                        this.companyInfo.type = 'Insurer';
                    }
                }
            },
            addImgUpload($event) {
                $event.previewElement.children[
                $event.previewElement.children.length - 1
                    ].style.display = "none";
            },
            completeImgUpload($event) {
                $event.previewElement.children[
                $event.previewElement.children.length - 1
                    ].removeAttribute("style");
            },
            sendImgUpload($event) {
                $event.previewElement.children[
                $event.previewElement.children.length - 1
                    ].style.display = "none";
            },
            successImgUploadBusinessProfile(file, response) {
                if (response.status) {
                    this.companyInfo.logoImgPath = file.dataURL;
                    this.$store.dispatch('currentCompany/loadCompanyInfo', {}, {root: true});
                }
                else {
                    setTimeout(() => {
                        this.$refs.imgUploadDropBusiness.removeFile(file);
                    }, 1000);
                }
            },
        },
        mounted() {
            this.setCompanyInfo(this.originalCompanyInfo);
        },
        watch: {
            originalCompanyInfo: {
                deep: true,
                handler(val) {
                    console.log('originalCompanyInfo', val);
                    this.setCompanyInfo(val);
                }
            },
        },
        computed: {
            ...mapGetters({
                originalCompanyInfo: "currentCompany/getDetails",
                addressStates: 'addressStates',
                addressCountries: 'addressCountries',
            }),
        },
    };
</script>
