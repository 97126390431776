<template>
  <div class="row settings-page">
    <div class="col-md-5">
      <b-table
          responsive
          hover
          :items="usersAll"
          :fields="usersAllFields"
          @row-clicked="onUsersRowClick"
          :tbody-tr-class="rowClass"
      >
        <template v-slot:cell(username)="data">
          {{ data.item.firstName }} {{ data.item.lastName }}
        </template>
        <template v-slot:cell(actions)="data">
          <button
                  class="btn-edit "
                  :class="{'current-user': data.item.id == currentUser.id}"
                  @click="onUserEdit(data.item)"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Edit User Details', placement:'leftbottom'}"
          ></button>
          <button v-if="data.item.id != currentUser.id"
                  class="btn-trush"
                  @click="onClickRemoveUser(data.item)"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Delete User', placement:'leftbottom'}"
          ></button>

        </template>
      </b-table>
    </div>

    <div class="col-md-7">
      <!-- Users Detail Tab View -->
      <div class="detail-section">
        <div class="section-header">Detail</div>

        <div class="detail-body row">
          <div v-if="selectedUser.userPhoto" class="col-md-4 user-avatar-section">
            <div class="user-photo">
              <img :src="selectedUser.userPhoto" alt="User Image"/>
            </div>

            <button v-if="isUsersEdit" class="btn btn-danger" @click="onClickDeletePhoto()">
              Delete Photo
            </button>

          </div>
          <div v-show="!selectedUser.userPhoto" class="col-md-4 user-avatar-section">
            <vue-dropzone
                v-on:vdropzone-file-added="addImgUpload"
                v-on:vdropzone-complete="completeImgUpload"
                v-on:vdropzone-sending="sendImgUpload"
                v-on:vdropzone-success="successImgUploadSelectedUser"
                ref="imgUpDropUsers"
                id="imgUpDropUsers"
                class="dropify-wrapper dropzoneContainer"
                :options="selectedUserAvatarDropdownOptions"
                :useCustomSlot="true">
              <div class="dropify-message" style="transform: translateY(0%);">
                <i class="bx bx-cloud-upload"></i>
                <p class="upload-title">Upload Photo</p>
              </div>
            </vue-dropzone>
          </div>

          <div class="col-md-8 user-detail-section">
            <div class="form-group row">
              <h3 class="user-name">{{ selectedUser.firstName }}
                {{ selectedUser.lastName }}</h3>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-form-label" for="account-status">Account Status</label>
              <div class="col-lg-9">
                <div v-if="!isUsersEdit || Number(selectedUser.id) === Number(currentUser.id)"
                     class="d-flex">
                  <label v-if="selectedUser.isActive" class="col-form-label form-text">
                    <div class="active-icon"></div>
                    <span class="active-user-text">Active User</span>
                  </label>
                  <label v-else class="col-form-label form-text">
                    <div class="active-icon inactive"></div>
                    <span class="active-user-text">Not Active User</span>
                  </label>
                </div>
                <div v-else
                     class="d-flex pt-0-25">
                  <b-form-checkbox
                          v-model="selectedUser.isActive"
                          name="check-button"
                          switch
                  ></b-form-checkbox>
                  <div class="status-text">
                    <span v-if="selectedUser.isActive" class="form-text">Active</span>
                    <span v-else class="form-text">Not Active</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label for="username" class="col-lg-3 col-form-label">Username</label>
              <div class="col-lg-9">
                <label
                    v-if="!isUsersEdit || Number(selectedUser.id) === Number(currentUser.id)"
                    class="col-form-label form-text"
                >{{ selectedUser.username }}</label>
                <input
                    v-else
                    type="text"
                    class="form-control"
                    v-model="selectedUser.username"
                    id="username"
                    @change="checkUsername(selectedUser.username)"
                />
                <small class="form-text text-danger" v-if="this.isCheckUsername && isUsersEdit">Username is already in use</small>
              </div>
            </div>

            <div class="form-group row">
              <label for="first_name" class="col-lg-3 col-form-label">First Name</label>
              <div class="col-lg-9">
                <label
                    v-if="!isUsersEdit"
                    class="col-form-label form-text"
                >{{ selectedUser.firstName }}</label>
                <input
                    v-else
                    type="text"
                    class="form-control"
                    v-model="selectedUser.firstName"
                    id="first_name"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="last_name" class="col-lg-3 col-form-label">Last Name</label>
              <div class="col-lg-9">
                <label
                    v-if="!isUsersEdit"
                    class="col-form-label form-text"
                >{{ selectedUser.lastName }}</label>
                <input
                    v-else
                    type="text"
                    class="form-control"
                    v-model="selectedUser.lastName"
                    id="last_name"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-form-label">Email Address</label>
              <div class="col-lg-9">
                <label
                    v-if="!isUsersEdit"
                    class="col-form-label form-text"
                >{{ selectedUser.email }}</label>
                <input
                    v-else
                    type="text"
                    class="form-control"
                    :value="selectedUser.email"
                    @change="validateSelectedUserEmail($event.target.value)"
                />
                <small
                    class="col-form-label form-text show-help-for-body"
                    v-if="!$v.selectedUser.email.email"
                >Please enter a valid email address</small>
              </div>
            </div>
            <div class="form-group row">
              <label for="location" class="col-lg-3 col-form-label">Business Location</label>
              <div class="col-lg-9">
                <label
                    v-if="!isUsersEdit"
                    for="location"
                    class="col-form-label form-text">
                  <template v-if="selectedUser.businessLocation">
                    {{ getLocation(selectedUser.businessLocation).name }}
                  </template>
                </label>
                <b-form-select
                    v-else
                    :options="locations"
                    v-model="selectedUser.businessLocation"
                    text-field="name"
                    value-field="id"
                    placeholder="Select location"
                >
                  <template #first>
                    <b-form-select-option value="null" disabled hidden>Select location</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </div>
            <div class="form-group row">
              <label for="work_phone" class="col-lg-3 col-form-label">Work Phone</label>
              <div class="col-lg-9">
                <label
                    v-if="!isUsersEdit"
                    for="work_phone"
                    class="col-form-label form-text"
                >{{ selectedUser.workPhone }}</label>
                <input
                    v-else
                    type="text"
                    class="form-control"
                    v-model="selectedUser.workPhone"
                    id="work_phone"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="mobile_phone" class="col-lg-3 col-form-label">Mobile Phone</label>
              <div class="col-lg-9">
                <label
                    v-if="!isUsersEdit"
                    for="mobile_phone"
                    class="col-form-label form-text"
                >{{ selectedUser.mobilePhone }}</label>
                <input
                    v-else
                    type="text"
                    class="form-control"
                    v-model="selectedUser.mobilePhone"
                    id="mobile_phone"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-form-label">Role</label>
              <div class="col-lg-9">
                <label
                        v-if="!isUsersEdit || selectedUser.id == currentUser.id"
                        class="col-form-label form-text"
                >{{ showRole(selectedUser) }}</label>
                <multiselect
                    v-else
                    ref="repairer-user-role-options"
                    v-model="selectedUser.roleValue"
                    :options="roles"
                    :showLabels="false"
                    :option-height="29"
                    :max-height="203"
                    :close-on-select="true"
                    placeholder="Select Role"
                />
              </div>
            </div>
            <div v-if="selectedUser.roleValue === 'Lawyer'" class="form-group row">
              <label class="col-lg-3 col-form-label">Legal Firm</label>
              <div class="col-lg-9">
                <label
                    v-if="!isUsersEdit || selectedUser.id == currentUser.id"
                    class="col-form-label form-text"
                >
                  {{ getLegalFirmNames(selectedUser)}}
                </label>
                <multiselect
                    v-else
                    ref="repairer-user-role-options"
                    v-model="legalFirm"
                    :options="legalFirms"
                    @select="selectLegalFirm"
                    :showLabels="false"
                    label="name"
                    @input="changedLegalFirms"
                    @remove="removeLegalFirm"
                    track-by="id"
                    :option-height="29"
                    :max-height="203"
                    :multiple="true"
                    :close-on-select="true"
                    placeholder="Select Legal Firm"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-form-label">Last Logged In</label>
              <div class="col-lg-9">
                <label class="col-form-label form-text">{{ selectedUser.lastLoggedIn }}</label>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-form-label">Active sessions</label>
              <div v-if="selectedUser.username" class="col-lg-9">
                <label class="col-form-label form-text">{{ selectedUser.activeSessions }}</label>
              </div>
            </div>
            <div class="form-group row action-btns" v-show="!isAddNewUserClicked">
              <template v-if="isUsersEdit">
                <button class="edit-btn float-right" @click="onClickCancelEditUser()">Cancel</button>
                <button class="update-btn float-right" @click="onClickUpdateUser()" :disabled="isCheckUsername">Update</button>
              </template>
              <template v-else>
                <button v-if="selectedUser.id != currentUser.id" class="delete-user float-right" @click="onClickRemoveUser()">
                  Delete User
                </button>
                <button class="close-active-btn float-right" @click="onClickCloseActiveSessions()">
                  Close Active Sessions
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="update-password-section">
        <div class="section-header">
          <div>Password</div>
        </div>

        <div class="form-body">
          <div class="form-group row mt-2">
            <label class="col-lg-4 col-form-label"
                   for="password_of_selected_user">Password</label>
            <div class="col-lg-8">
              <input
                  type="password"
                  class="form-control"
                  v-model="selectedUser.password"
                  :name="'selected_user_' + selectedUser.id"
                  id="password_of_selected_user"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="confirm_password_of_selected_user" class="col-lg-4 col-form-label">Confirm
              Password</label>
            <div class="col-lg-8">
              <input
                  type="password"
                  class="form-control"
                  v-model="selectedUser.confirmPassword"
                  :name="'selected_user_confirm_' + selectedUser.id"
                  id="confirm_password_of_selected_user"
              />
            </div>
          </div>

          <div class="form-group row action-btns">
            <button v-if="!isAddNewUserClicked" class="update-btn float-right"
                    @click="onClickUpdatePassword()">Update
            </button>
            <button v-else class="update-btn float-right" @click="onClickSaveUser()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
  import _ from "lodash";
  import Multiselect from 'vue-multiselect';
  import {email} from "vuelidate/lib/validators";
  import {mapGetters} from "vuex";
  import vue2Dropzone from "vue2-dropzone";
  import "vue2-dropzone/dist/vue2Dropzone.min.css";
  import Axios from "axios";
  import {appConfig} from '../../config.js';
  import ToggleButton from 'toggle-button';

  export default {
  name: "Users",
  components: {
    vueDropzone: vue2Dropzone,
    multiselect: Multiselect,
    ToggleButton: ToggleButton,
  },
  props: {
    onCurrentUserSelected: Function,
    cancelAddingUser: Function,
    isAddingUser: Boolean,
    addUserTrigger: Number,
  },
  watch: {
    isAddingUser(newVal){
      if(newVal){
        this.onClickAddNewUser();
      } else {
        this.onClickCancelEditUser();
      }
    },
    addUserTrigger(){
      this.addNewUser();
    },
  },
  data() {
    return {
      legalFirm: [],
      currentUsername: null,
      isCheckUsername: false,
      isUploading: false,
      usersAll: [],
      isUsersEdit: false,
      isAddNewUserClicked: false,
      lastSelectedUser: {
        id: '',
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        address: '',
        businessLocation: '',
        mobilePhone: '',
        workPhone: '',
        lastLoggedIn: '',
        activeSessions: -1,
        password: '',
        confirmPassword: '',
        userPhoto: '',
        roleValue:{}
      },
      selectedUser: {
        id: '',
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        address: '',
        businessLocation: '',
        mobilePhone: '',
        workPhone: '',
        lastLoggedIn: '',
        activeSessions: -1,
        password: '',
        confirmPassword: '',
        userPhoto: '',
        roleValue:{}
      },
      usersAllFields: [
        {
          label: "Name",
          key: "fullName",
          tdClass: "name name-td clickable font-weight-bold",
          thClass: "table-header"
        },
        {
          label: "Role",
          key: "role",
          tdClass: "role role-td clickable",
          thClass: "table-header"
        },
        {
          label: "",
          key: "actions",
          tdClass: "actions clickable text-right",
          thClass: "table-header"
        }
      ],
      selectedUserAvatarDropdownOptions: {
        headers: {Authorization: localStorage.getItem("token")},
        resizeWidth: 400,
        resizeQuality: 0.7,
        url: 'url', // need to input dynamic url
        method: "post",
        maxFilesize: 5,
        thumbnailWidth: 160,
        thumbnailHeight: 160,
        addRemoveLinks: false,
        acceptedFiles: "image/jpeg, image/jpg, image/png",
        dictFileTooBig:
            "Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again"
      },
      locations: [],
    }
  },
  mounted() {
    this.$store.dispatch('legalfirm/getLegalfirms');
    this.loadLocations();
    this.loadUsers(1, (users) => {
      if (!_.isEmpty(users)) {
        this.updateSelectedUser(users[0]);
      }
    });
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser/getCurrentUserInfo",
      isAssessments: 'currentCompany/isAssessments',
      isInsurance: 'currentCompany/isInsurance',
      isAccidentManagement: 'currentCompany/isAccidentManagement',
      getterLegalFirms: 'legalfirm/getAllLegalfirm',
      // roles: 'roles'
    }),
    legalFirms() {
      let firms = _.orderBy(this.getterLegalFirms.slice(), ['name'], ['asc']);
      firms.unshift({
        id: -1,
        name: 'All',
      });
      return firms;
    },
    roles(){
      if (this.isAssessments) {
        return [
          'Administrator',
          'Manager',
          'Assessor',
          'Lawyer',
        ];
      }else{
        return [
          'Administrator',
          'Manager',
          'Assessor',
          'Lawyer',
          'Customer Service',
          'Tow Driver',
        ];
      }
    },
  },
  validations: {
    selectedUser: {
      email: {
        email
      }
    },
    currentUser: {
      email: {
        email
      }
    }
  },
  methods: {
    getLegalFirmNames(user) {
      if (!user || !user.lawyer) {
        return "";
      }

      if (user.lawyer.showAll) {
        let names = "";
        _.forEach(this.getterLegalFirms, (lf) => {
          names += lf.name + ' | ';
        });
        return names;
      }

      if (user.lawyer.ids) {
        let names = "";
        _.forEach(user.lawyer.ids, (id) => {
          let lf = _.find(this.getterLegalFirms, (lf) => {
            return Number(lf.id) === Number(id);
            // return Number(lf.id) === Number(id.legal_firm_id);
          })
          if (lf) {
            names += lf.name + ' | '
          }
        });
        return names;
      }
      return "";
    },
    setLegalFirm(user) {
      this.legalFirm.splice(0, this.legalFirm.length);
      if (!user || !user.lawyer) {
        return;
      } else if (user.lawyer.showAll) {
        _.forEach(this.getterLegalFirms, (lf) => {
          this.legalFirm.push(lf);
        })
      } else if (user.lawyer.ids) {
        _.forEach(user.lawyer.ids, (id) => {
          let firm = _.find(this.getterLegalFirms, (lf) => {
            // return Number(lf.id) === Number(id.legal_firm_id);
            return Number(lf.id) === Number(id);
          })
          if (firm) {
            this.legalFirm.push(firm);
          }
        })
      }
    },
    removeLegalFirm() {
      this.selectedUser.lawyer.showAll = false;
    },
    selectLegalFirm(lf) {
      if (lf.id === -1 && lf.name === 'All') {
       this.legalFirm.splice(0, this.legalFirm.length);
       _.forEach(this.legalFirms, (firm) => {
         if (firm.id !== -1 && firm.name !== 'All') {
           this.legalFirm.push(firm);
         }
       });
       this.selectedUser.lawyer.showAll = true;
      }
    },
    changedLegalFirms() {
      let index = _.findIndex(this.legalFirm, (firm) => {
        return firm.id === -1 && firm.name === 'All';
      });
      if (index !== -1) {
        this.legalFirm.splice(index, 1);
      }
    },
    checkUsername: function (username) {
      if(username == this.currentUsername) {
        this.isCheckUsername = false;
        return;
      }
      Axios({url: '/ir/auth/check/username', data: {username: username}, method: 'POST'})
          .then(resp => {
            if (resp && resp.data && resp.data.status == false) {
              this.isCheckUsername = false;
            } else {
              this.isCheckUsername = true;
            }
          })
          .catch(err => {
            console.log(err);
          });
    },
    loadLocations(){
      Axios.get('/ir/company/locations')
          .then(res => {
            if(res && res.data && res.data._status){
              this.locations = res.data.locations;
            }
          })
          .catch(e => this.$toast.error(e))
    },
    getLocation(id){
      console.log('ID', id)
      return _.find(this.locations, (item) => {
        return id === item.id;
      });
    },
    showRole(item)
    {
      return item.role;
      // let role = _.find(this.roles, role => { return role.value === item})
      // if (role){
      //   return role.label
      // }
      // return '';
    },
    rowClass(item/*, type*/){
      let result = '';

      if (item && item.id === this.selectedUser.id) {
        result = 'selected-td';
      }
      return result;
    },
    loadUsers: function (pageNumber = 1, usersLoaded = (/*users*/) => {}) {
      NProgress.start();
      Axios.get('/ir/users', {
        params: {
          pageNum: pageNumber,
          sort_by: 'user_id',
          sort_type: 'DESC'
        }
      }).then(response => {
        //console.log('Users loaded',response.data.users);
        let users = _.map(response.data.users, item => {
          item.id = Number(item.id);
          return item;
        });
        users = _.sortBy(users, ['id']);
    //    console.log('Users mapped',users);
        this.usersAll = users;
        usersLoaded(users);
        NProgress.done();
      }).catch(error => {
        console.log(error);
        NProgress.done();
      });
    },
    validateForm: function (withPassword = false) {
      let result = true;
      if (this.selectedUser.username === '') {
        this.$toast.error('The Username must be specified');
        result = false;
      }
      else if (this.selectedUser.firstName === '') {
        this.$toast.error('The First Name must be specified');
        result = false;
      }
      else if (this.selectedUser.lastName === '') {
        this.$toast.error('The Last Name must be specified');
        result = false;
      }
      else if (_.isEmpty(this.selectedUser.roleValue)) {
        this.$toast.error('The Role must be specified');
        result = false;
      }
      else if (this.selectedUser.newPassword === '') {
        this.$toast.error('The Password must be specified');
        result = false;
      }
      else if (this.selectedUser.email === '') {
        this.$toast.error('The Email must be specified');
        result = false;
      }
      else if (withPassword){
        result = this.validateSelectedUserPassword();
      }

      return result;
    },
    validateSelectedUserPassword: function () {
      let result = true;
      if (!this.selectedUser.password) {
        this.$toast.error('Please input the password!');
        result = false;
      }
      if (this.selectedUser.password !== this.selectedUser.confirmPassword) {
        this.$toast.error('Passwords do not match!');
        result = false;
      }
      return result;
    },
    validateSelectedUserEmail: function (value) {
      this.selectedUser.email = value;
      this.$v.selectedUser.email.$touch();
    },
    onUsersRowClick: function (item) {
      this.updateSelectedUser(item);
      this.isUsersEdit = false;
      this.isAddNewUserClicked = false;
    },
    onClickAddNewUser: function () {
      this.isUsersEdit = true;
      this.isAddNewUserClicked = true;
      this.lastSelectedUser = this.selectedUser;
      this.updateSelectedUser({});
    },
    onClickCancelEditUser: function () {
      if (this.isAddNewUserClicked) {
        this.updateSelectedUser(this.lastSelectedUser);
      }
      this.isUsersEdit = false;
      this.isAddNewUserClicked = false;
    },
    onClickSaveUser: function () {
      this.addNewUser();
    },
    addNewUser: function () {
      if(!this.isAddNewUserClicked){
        return;
      }
      if (!this.validateForm(true)) {
        return false;
      }

      if (this.selectedUser.roleValue === 'Lawyer') {
        if (!this.selectedUser.lawyer.showAll) {
          this.selectedUser.lawyer.ids = [];
          _.forEach(this.legalFirm, (lf) => {
            this.selectedUser.lawyer.ids.push(lf.id);
          });
        } else {
          this.selectedUser.lawyer.ids = [];
        }
      }
//       console.log(this.selectedUser);
// return;
      let data = this.getFormData(this.selectedUser, true);

      if (this.$v.$anyError) {
        this.$toast.error('Your changes could not be saved, please check the form fields again');
        return;
      }
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/ir/user/new`,
        validateStatus: function (status) {
          return status < 500;
        },
        data: data
      })
          .then(response => {
            if (response.status === 200 && response.data && response.data.status) {
              this.$toast.success('New user created!');
              this.isUsersEdit = false;
              this.isAddingUser = false;
              this.isAddNewUserClicked = false;
              this.cancelAddingUser();
              this.loadUsers(1, (users) => {
                if (!_.isEmpty(users)) {
                  this.updateSelectedUser(users[users.length-1]);
                }
              });
              return;
            } else {
              this.$toast.error(response.data.msg);
            }
          })
          .catch(error => {
            console.error(error);
          });
    },
    updateUser: function (user, withPassword = false) {
      if (!this.validateForm(withPassword)) {
        return false;
      }

     // console.log('withPassword', withPassword)

      let data = this.getFormData(user, withPassword);

     // console.log('FORM DATA ', data)

      NProgress.start();
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/ir/user/${user.id}`,
        validateStatus: function (status) {
          return status < 500;
        },
        data: data
      })
              .then(response => {
        if (response.status === 200) {
          this.$toast.success('The user updated!');
          this.selectedUser.password = "";
          this.selectedUser.confirmPassword = "";
          this.loadUsers(1, (users) => {
            if (!_.isEmpty(users)) {
              this.updateSelectedUser(_.find(users, user => { return user.id === this.selectedUser.id}));
            }
          });
          if (this.selectedUser.id == this.currentUser.id) {
            this.$store.dispatch('currentUser/loadUserInfo');
          }
        } else {
          this.$toast.error(response.data.msg);
        }
              })
              .catch(error => {
        console.error(error);

              })
              .finally(() => {
                NProgress.done();
              });
    },
    getFormData(user, withPassword = false){
      let data =  {
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        address: user.address,
        businessLocation: user.businessLocation,
        workPhone: user.workPhone,
        mobilePhone: user.mobilePhone,
        username: user.username,
        role: user.roleValue,
        isActive: user.isActive,
        avatarFileId: user.avatarFileId,
      };
      if (user.roleValue == "Lawyer"){
        data.lawyer = user.lawyer;
      }
      if(withPassword){
        data.password = user.password;
        data.confirmPassword = user.confirmPassword;
      }
      return data;
    },
    onClickUpdateUser: function () {
      console.log('update',this.selectedUser);
      if (this.selectedUser.role === 'Lawyer') {
        if (!this.selectedUser.lawyer.showAll) {
          this.selectedUser.lawyer.ids = [];
          _.forEach(this.legalFirm, (lf) => {
            this.selectedUser.lawyer.ids.push(lf.id);
          });
        } else {
          this.selectedUser.lawyer.ids = [];
        }
      }
      this.updateUser(this.selectedUser);
      this.isUsersEdit = false;
      this.isAddNewUserClicked = false;
    },
    setUploadUrlForUsersPhoto: function (id) {
      if (!_.isEmpty(this.$refs.imgUpDropUsers)) {
        let url = appConfig.baseAPIURL + "/ir/user/" + id + "/photo/upload";
        this.$refs.imgUpDropUsers.setOption('url', url);
      }
    },
    onUserEdit: function (item) {
      this.updateSelectedUser(item);
      this.isUsersEdit = true;
      this.isAddNewUserClicked = false;
      this.isCheckUsername = false;
    },
    onClickRemoveUser: function (item) {
      let userId = (!item) ? this.selectedUser.id : item.id;

      NProgress.start();
      Axios.post('/ir/user/' + userId + '/delete', {userId: userId})
          .then(response => {
            if (response.status !== 200 || !response.data._status) {
              this.$toast.error(response.data._msg);
            } else {
              this.$toast.success("User deleted");
              this.loadUsers(1);
            }
            NProgress.done();
          })
          .catch(error => {
            console.log(error);
            NProgress.done();
          });
      this.updateSelectedUser({});
    },
    onClickCloseActiveSessions: function () {
      NProgress.start();
      Axios.post('/ir/user/' + this.selectedUser.id + '/closeActiveSessions')
          .then((/*response*/) => {
            this.$toast.success('Active sessions have been closed');
            this.loadUsers(1);
          })
          .catch(error => {
            this.$toast.error('Active sessions have not been closed');
            console.log(error);
          }).finally(()=>{
            NProgress.done();
          });
      this.isUsersEdit = false;
      this.isAddNewUserClicked = false;
    },
    onClickUpdatePassword: function () {
      this.updateUser(this.selectedUser, true);
    },
    onUnlockPassword: function () {
    },
    onClickDeletePhoto() {
      this.selectedUser.userPhoto = '';
      NProgress.start();
      Axios.post('/ir/user/' + this.selectedUser.id + '/photo/delete')
              .then((/*response*/) => {
                this.$toast.success('User photo has been removed');
                this.loadUsers(1);
                if (this.selectedUser.id == this.currentUser.id) {
                  this.$store.dispatch('currentUser/loadUserInfo');
                }
              })
              .catch(error => {
                this.$toast.error('User photo has not been removed');
                console.log(error);
              })
              .finally(() => {
        NProgress.done();
      });

    },
    updateSelectedUser: function (data) {
      console.log('DATA', data);
      if (!_.isEmpty(data)) {
        this.setUploadUrlForUsersPhoto(data.id);
        this.selectedUser = {
          id: data.id,
          username: data.username,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          role: data.role,
          isActive: data.isActive,
          address: data.address,
          businessLocation: data.businessLocation,
          userPhoto: data.userPhoto,
          mobilePhone: data.mobilePhone,
          workPhone: data.workPhone,
          activeSessions: data.activeSessions,
          lastLoggedIn: data.lastLoggedIn,
          roleValue: data.role,
        };
        if (data.role === 'Lawyer') {
          this.selectedUser.lawyer = data.lawyer;
        } else {
          this.selectedUser.lawyer = {};
        }
        this.setLegalFirm(this.selectedUser);
        this.currentUsername = data.username;
      } else {
        this.selectedUser = {
          id: '',
          username: '',
          firstName: '',
          lastName: '',
          email: '',
          role: '',
          isActive: true,
          address: '',
          businessLocation: '',
          userPhoto: '',
          mobilePhone: '',
          workPhone: '',
          lastLoggedIn: '',
          activeSessions: '',
          password: '',
          confirmPassword: '',
          roleValue: 'Manager',
          lawyer: {}
        }
        this.setLegalFirm(this.selectedUser);
      }

    },
    //Images
    addImgUpload($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].style.display = "none";
    },
    completeImgUpload($event) {
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].removeAttribute("style");
    },
    sendImgUpload($event) {
      this.isUploading = true;
      $event.previewElement.children[
      $event.previewElement.children.length - 1
          ].style.display = "none";
    },
    successImgUploadSelectedUser(file, response) {
      this.isUploading = false;
      if (response.status) {
        this.$set(this.selectedUser, 'userPhoto', file.dataURL);
        this.selectedUser.avatarFileId = response.photoId;
        this.updateUser(this.selectedUser);
      } else {
        this.$set(this.selectedUser, 'userPhoto', null);
      }
      setTimeout(() => {
        this.$refs.imgUpDropUsers.removeFile(file);
      }, 1000);

    }
  }
}
</script>

<style scoped>

.detail-body .user-photo img {
  width: 100%;
  height: auto;
}

.current-user {
  margin-right: 36px;
}

</style>
