<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="detail-section">
        <div class="section-header">Detail</div>

        <div class="detail-body row">
          <div v-if="myAccountAvatar" class="col-md-4 user-avatar-section">
            <div class="user-photo">
              <img :src="myAccountAvatar" alt="User Image"/>
            </div>
            <button v-show="isEditMode" class="btn btn-danger"
                    @click="onRemoveMyAccountPhoto()">Delete Photo
            </button>
          </div>
          <div v-else class="col-md-4 user-avatar-section">
            <vue-dropzone
                v-on:vdropzone-file-added="addImgUpload"
                v-on:vdropzone-complete="completeImgUpload"
                v-on:vdropzone-sending="sendImgUpload"
                v-on:vdropzone-success="successImgUploadMyAccount"
                ref="imgUploadDropMyAccount"
                id="imgUploadDropMyAccount"
                class="dropify-wrapper dropzoneContainer"
                :options="myAccountDropzoneOptions"
                :useCustomSlot="true"
            >
              <div class="dropify-message" style="transform: translateY(0%);">
                <i class="bx bx-cloud-upload"></i>
                <p class="upload-title">Upload Photo</p>
              </div>
            </vue-dropzone>
          </div>

          <div class="col-md-8 user-detail-section">
            <div class="form-group row">
              <h3 class="user-name">{{ currentUser.fullName }}</h3>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-form-label" for="account-status">Account
                Status</label>
              <div class="col-lg-9 d-flex">
                <label v-if="isActiveMyAccount" class="col-form-label form-text">
                  <div class="active-icon"></div>
                  <span class="active-user-text">Active User</span>
                </label>
                <label v-else class="col-form-label form-text">
                  <div class="active-icon inactive"></div>
                  <span class="active-user-text">Not Active User</span>
                </label>
              </div>
            </div>
            <div class="form-group row">
              <label for="username_my_account"
                     class="col-lg-3 col-form-label">Username</label>
              <div class="col-lg-9">
                <label
                    v-if="!isEditMode || 1==1"
                    class="col-form-label form-text"
                >{{ currentUser.username }}</label>
                <input
                    v-else
                    type="text"
                    class="form-control"
                    v-model="currentUser.username"
                    id="username_my_account"
                />
              </div>
            </div>

            <div class="form-group row">
              <label for="first_name_my_account" class="col-lg-3 col-form-label">First
                Name</label>
              <div class="col-lg-9">
                <label
                    v-if="!isEditMode"
                    class="col-form-label form-text"
                >{{ currentUser.firstName }}</label>
                <input
                    v-else
                    type="text"
                    class="form-control"
                    v-model="currentUser.firstName"
                    id="first_name_my_account"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="last_name_my_account" class="col-lg-3 col-form-label">Last
                Name</label>
              <div class="col-lg-9">
                <label
                    v-if="!isEditMode"
                    class="col-form-label form-text"
                >{{ currentUser.lastName }}</label>
                <input
                    v-else
                    type="text"
                    class="form-control"
                    v-model="currentUser.lastName"
                    id="last_name_my_account"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-form-label">Email Address</label>
              <div class="col-lg-9">
                <label
                    v-if="!isEditMode"
                    class="col-form-label form-text"
                >{{ currentUser.email }}</label>
                <input
                    v-else
                    type="text"
                    class="form-control"
                    v-model="currentUser.email"
                />
                <small
                    class="col-form-label form-text show-help-for-body"
                    v-if="false"
                >Please enter a valid email address</small>
              </div>
            </div>
            <div class="form-group row">
              <label for="location" class="col-lg-3 col-form-label">Location</label>
              <div class="col-lg-9">
                <label
                    v-if="!isEditMode"
                    for="location_my_account"
                    class="col-form-label form-text">
                  <template v-if="currentUser.businessLocation">
                    {{ getLocation(currentUser.businessLocation).name }}
                  </template>
                </label>
                <b-form-select
                    v-else
                    :options="locations"
                    v-model="currentUser.businessLocation"
                    value-field="id"
                    text-field="name"
                    placeholder="Select business location"
                >
                  <template #first>
                    <b-form-select-option value="null" disabled hidden>Select business location</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </div>
            <div class="form-group row">
              <label for="work_phone" class="col-lg-3 col-form-label">Work Phone</label>
              <div class="col-lg-9">
                <label
                    v-if="!isEditMode"
                    for="work_phone_my_account"
                    class="col-form-label form-text"
                >{{ currentUser.workPhone }}</label>
                <input
                    v-else
                    type="text"
                    class="form-control"
                    v-model="currentUser.workPhone"
                    id="work_phone_my_account"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="mobile_phone_my_account" class="col-lg-3 col-form-label">Mobile
                Phone</label>
              <div class="col-lg-9">
                <label
                    v-if="!isEditMode"
                    for="mobile_phone"
                    class="col-form-label form-text"
                >{{ currentUser.mobilePhone }}</label>
                <input
                    v-else
                    type="text"
                    class="form-control"
                    v-model="currentUser.mobilePhone"
                    id="mobile_phone_my_account"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-form-label">Role</label>
              <div class="col-lg-9">
                <label
                    class="col-form-label form-text"
                >{{ currentUser.role }}</label>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-form-label">Last Logged In</label>
              <div class="col-lg-9">
                <label
                    class="col-form-label form-text">{{
                    currentUser.lastLoggedIn
                  }}</label>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-form-label">Active sessions</label>
              <div class="col-lg-9">
                <label
                    class="col-form-label form-text">{{
                    currentUser.activeSessions
                  }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="detail-footer">
          <div class="form-group w-auto action-btns d-flex justify-content-center m-auto">
            <button class="edit-btn" v-if="!isEditMode" @click="onEditAccount()">Edit
            </button>
            <button class="update-btn" v-if="isEditMode" @click="onUpdateMyAccountInfo()">
              Update
            </button>
            <button class="edit-btn" v-if="isEditMode" @click="onCancelEditAccount()">
              Cancel
            </button>
            <button v-if="!isEditMode"
                    class="close-active-btn"
                    @click="onCloseActiveSessionsMyAccount()"
            >Close Active Sessions
            </button>
          </div>
        </div>
      </div>

      <div class="update-password-section">
        <div class="section-header">Password</div>

        <div class="form-body">
          <div class="form-group row mt-3">
            <label class="col-lg-4 col-form-label" for="password_my_account">Current
              Password</label>
            <div class="col-lg-8">
              <input
                  type="password"
                  class="form-control"
                  v-model="currentPassword"
                  id="password_my_account"
                  :disabled="passwordLocked"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"
                   for="new_password__my_account">Password</label>
            <div class="col-lg-8">
              <input
                  type="password"
                  class="form-control"
                  v-model="newPassword"
                  id="new_password_my_account"
                  :disabled="passwordLocked"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="confirm_password_of_my_account" class="col-lg-4 col-form-label">Confirm
              Password</label>
            <div class="col-lg-8">
              <input
                  type="password"
                  class="form-control"
                  v-model="confirmPassword"
                  id="confirm_password_of_my_account"
                  :disabled="passwordLocked"
              />
            </div>
          </div>

          <div class="form-group row action-btns justify-content-end">
            <button class="update-btn" @click="onUpdatePasswordMyAccount()" :disabled="passwordLocked">Update</button>
            <button v-if="passwordLocked" class="unlock-btn" @click="onUnLockPasswordMyAccount()">Unlock</button>
            <button v-else class="unlock-btn" @click="onLockPasswordMyAccount()">Lock</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import Axios from "axios";

export default {
  name: "settings",
  components: {
    vueDropzone: vue2Dropzone,
    multiselect: Multiselect,
  },
  props: {
    myAccountDropzoneOptions: Object,
  },
  data(){
    return {
      companyRole: null,
      currentUserLocation: null,
      locations: [],
      isEditMode: false,
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
      passwordLocked: true,
    };
  },
  mounted() {
    this.loadLocations();
    if (!_.isEmpty(this.currentUser)) {
      //this.myAccountAvatar = this.currentUser.userPhoto; //Error , because myAccountAvatar is the computed
      this.companyRole = this.currentUser.companyRole;
      this.currentUserLocation = this.getLocation(this.currentUser.businessLocation);
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser/getCurrentUserInfo",
      addressStates: 'addressStates',
      addressCountries: 'addressCountries',
    }),
    isActiveMyAccount(){
      return true;
    },
    myAccountAvatar(){
      return this.currentUser.userPhoto;
    },
  },
  methods: {
    addImgUpload(){},
    completeImgUpload(){},
    sendImgUpload(){},
    successImgUploadMyAccount(){
      this.$store.dispatch('currentUser/loadUserInfo')
          .then(() => {
          })
          .catch((err) => {
            this.$toast.error(err)
          });
    },
    getLocationById(){},
    onRemoveMyAccountPhoto(){
      Axios.post('/ir/user/photo/delete')
        .then(res => {
          if(res && res.data && res.data._status){
            this.$toast.success('Avatar deleted');
            this.$store.dispatch('currentUser/loadUserInfo')
                .then(() => {
                })
                .catch((err) => {
                  this.$toast.error(err)
                });
          } else {
            this.$toast.error('Cannot delete');
          }
        })
        .catch(e => this.$toast.error(e));
    },
    onEditAccount(){
      this.isEditMode = true;
    },
    onCancelEditAccount(){
      this.isEditMode = false;
    },
    onUpdateMyAccountInfo(){

      const updatedUserInfo = {
        username: this.currentUser.username,
        email: this.currentUser.email,
        companyRole: this.companyRole,
        isActive: this.isActiveMyAccount,
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
        businessLocation: this.currentUser.businessLocation ? this.currentUser.businessLocation : null,
        workPhone: this.currentUser.workPhone,
        mobilePhone: this.currentUser.mobilePhone,
      };
      this.$store.dispatch('currentUser/updateCurrentUser', updatedUserInfo)
          .then(() => {
            this.$toast.success('User has been updated successfully');
            // this.$store.dispatch('users/fetchAllUsers').catch(err => {
            //   this.$toast.error(err)
            // });
          }).catch(err => {
        this.$toast.error(err)
      })
      this.isEditMode = false;

    },

    onUpdatePasswordMyAccount(){
      Axios.post('/settings/update-password', {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
        repeatPassword: this.confirmPassword,
      })
      .then(res => {
        if(res && res.data && res.data._status){
          this.currentPassword = '';
          this.newPassword     = '';
          this.confirmPassword = '';
        }
      })
      .catch(e => console.warn(e));
    },
    onUnLockPasswordMyAccount(){
      this.passwordLocked = false;
    },
    onLockPasswordMyAccount(){
      this.passwordLocked = true;
    },
    onCloseActiveSessionsMyAccount(){
      this.$store.dispatch('currentUser/closeActiveSessions')
          .then(() => {
            this.$toast.success('Active sessions are closed successfully')
            this.$store.dispatch('auth/logout');
          })
          .catch((err) => {
            this.$toast.error(err)
          })
    },
    loadLocations(){
      Axios.get('/ir/company/locations')
          .then(res => {
            if(res && res.data && res.data._status){
              this.locations = res.data.locations;
            }
          })
          .catch(e => this.$toast.error(e))
    },
    getLocation(id){
      console.log('ID', id)
      return _.find(this.locations, (item) => {
        return id === item.id;
      });
    },
  }
}


</script>

<style scoped>
.settings-page .user-photo img {
  width: 100%;
  height: auto;
}
</style>
