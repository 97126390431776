<template>
    <div class="row">
        <tabs ref="business-profile-tabs" :options="{ useUrlFragment: false }"
              class="business-profile-tabs" @changed="onBusinessTabChangeLocal">
            <tab name="Details" id="settings-business-profile-details" class-name="content-table">
                <BusinessDetails ref="SettingsBusinessProfileDetails"
                ></BusinessDetails>
            </tab>
            <tab name="Contacts" id="settings-business-profile-contacts" class-name="content-table">
                <BusinessContacts ref="SettingsBusinessProfileContacts"
                ></BusinessContacts>
            </tab>
            <tab name="Locations" id="settings-business-profile-locations" class-name="content-table">
              <BusinessLocations ref="SettingsBusinessProfileLocations"
                                 :add-location-button-pressed="addLocationButtonPressed"
                                 :save-location-button-pressed="saveLocationButtonPressed"
              ></BusinessLocations>
            </tab>
            <tab name="Mailbox" id="settings-business-profile-mailbox" class-name="content-table">
              <BusinessMailbox ref="SettingsBusinessProfileMailbox"></BusinessMailbox>
            </tab>

            <template v-if="this.currentTab == 'settings-business-profile-mailbox'"
                      slot="nav-item-right">
              <div class="tabs-nav-right-btn">
                <button @click="$refs.SettingsBusinessProfileMailbox.clickSave()" class="btn btn-primary primary-save-btn">Save</button>
              </div>
            </template>
        </tabs>
    </div>

</template>
<script>
    /*eslint-disable */

    import BusinessDetails from "@/views/settings/business-profile/BusinessDetails";
    import BusinessContacts from "@/views/settings/business-profile/BusinessContacts";
    import BusinessLocations from "@/views/settings/business-profile/BusinessLocations";
    import BusinessMailbox from "@/views/settings/business-profile/BusinessMailbox";

    export default {
        name: "settings-business-profile",
        components: {
            BusinessDetails,
            BusinessContacts,
            BusinessLocations,
            BusinessMailbox,
        },
        props: {
            onBusinessTabChange: Function,
            addLocationButtonPressed: Number,
            saveLocationButtonPressed: Number,
        },
        data() {
            return {
              currentTab: null,
            };
        },
        computed: {
        },
        methods: {
            onBusinessTabChangeLocal(tab){
              this.currentTab = tab.tab.id;
              this.onBusinessTabChange(tab)
            },
            clickSaveBusinessDetails() {
                //console.log(this.$refs)
                this.$refs.SettingsBusinessProfileDetails.clickSave();
            },
            clickSaveBusinessContacts() {
                this.$refs.SettingsBusinessProfileContacts.clickSave();
            },
            clickSaveBusinessLocation() {
                this.$refs.SettingsBusinessProfileLocation.clickSave();
            }
        }
    };
</script>
